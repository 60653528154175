<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('打印面单')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
  </el-row>

  <el-row :gutter="10"  class="query-wrap" >
    <!-- <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-select v-model="codetype" placeholder="请选择">
          <el-option
            v-for="item in options"
            size='mini'
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
    </el-col> -->
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input v-if="!autoPrint"  :placeholder="msg('货单号/面单码')" v-model="code" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
        <el-input v-else :placeholder="msg('货单号/面单码')" ref="inputer1" @keyup.native="autoSearch" v-model="inputCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
       <el-input v-if="!autoPrint"  :placeholder="msg('物流袋号')" v-model="pkgCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-suitcase"></i>
        </el-input>
        <el-input v-else :placeholder="msg('物流袋号')" ref="inputer2" @keyup.native="autoSearchPkgCode" v-model="inputPkgCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-suitcase"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input v-if="!autoPrint"  :placeholder="msg('转寄单号')" v-model="resendCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
        <el-input v-else :placeholder="msg('转寄单号')" ref="inputer3" @keyup.native="autoSearchResendCode" v-model="inputResendCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
  </el-row>

  <el-row :gutter="10" type="flex" justify="space-between" align="center" class="ctrl-wrap">
    <el-col :span="24">
      <span style="font-size:13px; margin-top:5px; margin-right:4px;display:inline-block;">{{msg('开启自动打印')}}</span>
      <el-switch v-model="autoPrint" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
      <el-button type="success" @click="search()" size="mini" style="margin-left: 10px; ">
        <i class="el-icon-printer" style="display:inline-block;margin-right:4px; "></i>
        <transition name="el-fade-in-linear">
          <span v-show="!isMinScreen">{{msg('打印')}}</span>
        </transition>
      </el-button>
      <el-button type="primary" size="mini" @click="printFindTickets()" v-if="hasPermission('express:printFindTickets')">
        <i class="el-icon-edit" style="display:inline-block;margin-right:4px;"></i>
        <transition name="el-fade-in-linear">
          <span v-show="!isMinScreen">{{msg('打印找货单')}}</span>
        </transition>
      </el-button>
      <!--
      <el-button type="success" @click="doPrint()" size="mini" style="margin-left: 10px; ">
        <i class="el-icon-printer" style="display:inline-block;margin-right:4px; "></i>
        <transition name="el-fade-in-linear">
          <span v-show="!isMinScreen">{{msg('手动打印')}}</span>
        </transition>
      </el-button>
      -->
    </el-col>
  </el-row>
  
  <div :style="{height : docHeight+'px'}">
    <div class="content-panel" style="background: white; margin: 15px; padding: 15px; text-align:center; ">
      <div class="panel-wrap" style="margin:0px auto;">
        <vue-easy-print tableShow ref="easyPrint">
          <template>
            <div >
              <div class="print" v-for="(pg, idx) in pages" :key="idx">
                <img v-if="pg.status" :src="'data:image/png;base64,'+pg.page" :style="getStyle(pg)" ref="imgs"/>
                <span v-else style="display:inline-block; font-size:25px; margin-top:100px; margin-left:30px;; text-align:center;">{{pg.code}}<br/>{{pg.msg}}</span>
                <div v-if="pages.length - 1 > idx" style="page-break-before: always; margin-top:10px; margin-bottom:10px;"></div> 
              </div><!--end print-->
            </div>
          </template>
        </vue-easy-print>
      </div>
    </div>
  </div>

  <vue-easy-print tableShow ref="easyPrint1">
    <template>
      <order-ticket-tpl :tickets="printOrders" ></order-ticket-tpl>
    </template>
  </vue-easy-print>

  <!-- <div style="display:inline-block; width:1px; height:1px; overflow:hidden;">
    <audio src="/media/1.wav" controls="controls" preload ref="wav"></audio>
  </div> -->
  <div style="display:inline-block; width:1px; height:1px; overflow:hidden;">
      <audio controls="controls" preload ref="audioRef" ></audio>
  </div>
</div> 
</template> 

<script>
// @ is an alias to /src
import wav1 from "../../assets/media/1.wav";
import mp3711 from "../../assets/media/711.mp3";
import DDD from "../../assets/media/DDD.mp3";
import HM from "../../assets/media/HM.mp3";
import LRF from "../../assets/media/LRF.mp3";
import MJZP from "../../assets/media/MJZP.mp3";
import OK from "../../assets/media/O&K.mp3";
import QJ from "../../assets/media/QJ.mp3";
import XPZP from "../../assets/media/XPZP.mp3";
import XZ from "../../assets/media/XZ.mp3";
import cancel from "../../assets/media/cancel.mp3";
import vueEasyPrint from "vue-easy-print";
import ExpPrintFindTicketTpl from "./ExpPrintFindTicketTpl.vue";

export default {
  name: 'ExpPrintPage',
  components: {
    vueEasyPrint:vueEasyPrint,
    OrderTicketTpl:ExpPrintFindTicketTpl,
  },
  mounted:function(){
  },
  data(){
    return{
      code: null,
      prevCode: null,

      pkgCode: null,
      prevPkgCode: null,

      resendCode:null,
      prevResendCode:null,

      printOrders: [],

      pages: [],
      autoPrint: false,
      inputing: false,
      timer: null,
      timer1: null,
      actived: null,
      totalImages: 0,
      options: [{
          value: 0,
          label: '货单号'
        }, {
          value: 1,
          label: '面单码'
        }
        ],
      codetype:null,
    } 
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    inputCode: {
      get(){
        return this.code;
      },
      set(newValue){
        this.preCode = this.code;
        this.code = newValue;
      }
    },
    inputPkgCode: {
      get(){
        return this.pkgCode;
      },
      set(newValue){
        this.prevPkgCode = this.pkgCode;
        this.pkgCode = newValue;
      }
    },
    inputResendCode: {
      get(){
        return this.resendCode;
      },
      set(newValue){
        this.prevResendCode = this.resendCode;
        this.resendCode = newValue;
      }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 238;
        }
    }
  },
  methods: {
    playAudio(src){
        let audio = this.$refs.audioRef;
        audio.src = src;
        audio.play();
    },
    search(){
      
      if(!(this.code || this.pkgCode || this.resendCode)){
        this.$message("订单编号或者袋号或者转寄单号必须有一个不能为空");
        return;
      }

      this.totalImages = 0;

      this.axios.get(this.$kit.api.expPrintPage+"?c="+(this.code?encodeURIComponent(this.code):"")+"&p="+(this.pkgCode?this.pkgCode:"")+"&r="+(this.resendCode?this.resendCode:"")).then((result) => {
        if(result.status){
          var datas = result.data;
          this.pages.splice(0);
          // for(var i=0; i<datas.length; i++){
          //   this.pages.push({
          //     id: datas[i],
          //     url: this.$kit.api.pdf+"?id="+datas[i]
          //   });
          // }
          this.pages = datas;
          let company = this.pages[0].expCompany;
          if(this.pages[0] && this.pages[0].expCompany){
            if(company.indexOf('7-11')>-1){
              this.playAudio(mp3711);
            }else if(company.indexOf('全家')>-1){
              this.playAudio(QJ);
            }else if(company.indexOf('黑貓')>-1 || company.indexOf('黑猫')>-1){
              this.playAudio(HM);
            }else if(company.indexOf('莱尔富')>-1){
              this.playAudio(LRF);
            }else if(company.indexOf('OK')>-1){
              this.playAudio(OK);
            }else if(company.indexOf('賣家宅配')>-1){
              this.playAudio(MJZP);
            }else if(company.indexOf('蝦皮宅配')>-1){
              this.playAudio(XPZP);
            }else if(company.indexOf('店到店')>-1){
              this.playAudio(DDD);
            }else if(company.indexOf('新竹')>-1){
              this.playAudio(XZ);
            }
          }

          let msg = this.pages[0].msg;
          if(msg && msg.indexOf("取消")>-1){
            this.playAudio(cancel);
            this.clearInputer();
          }

          if(msg && msg.indexOf("没有面单")>-1){
            this.clearInputer();
          }

          setTimeout(()=>{
            let msg = this.pages[0].msg;
            if(msg && (msg.indexOf("取消") > -1 || msg.indexOf("没有面单")>-1)){
              console.log(msg);
            }else{
              this.doPrint();
            }
          }, 1000);

        }else{
          this.$message(result.msg);
          // this.$refs.wav.play();
          this.playAudio(wav1);
          this.clearInputer();
        }
        
      }).catch(function (error) {
        console.error(error);
        this.clearInputer();
      });

    },
    clearInputer(){
      this.code = null;
      this.prevCode = null;
    
      this.pkgCode = null;
      this.prevPkgCode = null; 
    },
    doPrint(){
      
      this.$refs.easyPrint.print();

      if(this.autoPrint){
        this.clearInputer();

        // 正确代码
        this.$nextTick(() => {

          if(this.actived){
            if(this.actived=='code'){
              this.$refs.inputer1.focus();
            }else if(this.actived=='pkgCode'){
              this.$refs.inputer2.focus();
            }else if(this.actived=='resendCode'){
              this.$refs.inputer3.focus();
            }else{
              this.$refs.inputer1.focus();
            }
          }

        });

      }
    },
    autoSearch(){
      var _this = this;
      //debugger;
      if(this.inputing == false){
        this.inputing = true;
        this.actived = 'code';
        this.timer = setInterval(()=>{
          if(_this.inputing && _this.code){
            if(_this.prevCode == _this.code){
              clearInterval(_this.timer);
              _this.timer = null;
              _this.inputing = false;
              _this.search();
            }else{
              _this.prevCode = _this.code;
            }
          }
          
        }, 1000);
      }
    },
    autoSearchPkgCode(){
      var _this = this;
      //debugger;
      if(this.inputing == false){
        this.inputing = true;
        this.actived = 'pkgCode';
        this.timer = setInterval(()=>{
          if(_this.inputing && _this.pkgCode){
            if(_this.prevPkgCode == _this.pkgCode){
              clearInterval(_this.timer);
              _this.timer = null;
              _this.inputing = false;
              _this.search();
            }else{
              _this.prevPkgCode = _this.pkgCode;
            }
          }
          
        }, 1000);
      }
    },
    autoSearchResendCode(){
      var _this = this;
      //debugger;
      if(this.inputing == false){
        this.inputing = true;
        this.actived = 'resendCode';
        this.timer = setInterval(()=>{
          if(_this.inputing && _this.resendCode){
            if(_this.prevResendCode == _this.resendCode){
              clearInterval(_this.timer);
              _this.timer = null;
              _this.inputing = false;
              _this.search();
            }else{
              _this.prevResendCode = _this.resendCode;
            }
          }
          
        }, 1000);
      }
    },

    //货取打印找货单的数据
    printFindTickets(){
      this.axios.get(this.$kit.api.expPrintFind)
        .then( (result) => {
          this.$message(result.msg);
          if(result.status){
            this.printOrders = result.data;

            if(this.printOrders.length > 0){
              setTimeout(()=>{
                this.$refs.easyPrint1.print();
              }, 500);
            }
          }
        }).catch(function (error) {
          console.error(error);
        });
    },
    getStyle(dto){
      if(dto.newPage){
        let style = {width: "400px"}
        if(dto.expCompany==='7-11'){
          style = {width: "360px"};
        }else if(dto.expCompany === '全家'){
          if(dto.platform == 1){
            style={width: '390px'}
          }else{
            style={width: '380px'}
          }
        }else if(dto.expCompany=='莱尔富'){
          if(dto.platform == 2){
            style={width: '380px'}
          }
        }
        return style;
      }else{
        let style = {};
        if(dto.expCompany == '7-11'){
            style= {
              width: "340px",
              maxHeight: "600px"
            }
        }else if(dto.expCompany == 'OK'){
            style={
              height:"560px"
            }
        }else if(dto.expCompany == '全家'){
            style={
              height: "600px"
            }
        }else if(dto.expCompany != "7-11"){
          style={
            width: "400px",
            maxHeight: "600px"
          }
        }
        return style;
      }
    }
  }
}
</script>

<style>
.el-col-lg-6 {
    width: 22%;
}
</style>
