<!-- 打印面单，货单-->
<template>
<div class="content-panel">
  <div class="panel-wrap">
    <div class="print" v-for="(ticket, idx) in tickets" :key="ticket.id">
      <div>
        <img v-if="ticket.page" :src="'data:image/png;base64,'+ticket.page" :style="getStyle(ticket)" />
        <div v-else style="text-align:center; margin-top:5px; font-size:24px;">{{msg("面单不存在")}}<br/>{{ticket.orderCode}}</div>
        <div v-if="tickets.length ==1 && tickets.length - 1 > idx" style="page-break-before: always; margin-top:10px; margin-bottom:10px;"></div> 
      </div>
      <div class="print-body" v-if="ticket && ticket.orders && ticket.orders.length>1">
        <div style="position:relative; margin:10px auto 0px auto;">
          <div style="text-align:center;">
            <img :src="'data:image/png;base64,'+ticket.lineCodeAsBase64" class="qrcode" style="margin-left:50px;"/>
            <div style="margin-left:50px; font-size:14px; ">{{ticket.orderCode}}</div>
          </div>
          <span style="position:absolute; left:25px; top:6px; font-size:20px; font-weight:bold;">{{ticket.expCompany}}</span>
        </div>
        <div class="br" />
        <h1>發票 INVOICE</h1>
        <div class="line">
          <div class="line-block">
            <span class="cell">單&nbsp;&nbsp;號：</span>
            <span class="txt">{{ticket.orderCode}}</span>
          </div>
          <div class="line-block">
            <span class="cell">寄件人：</span>
            <span class="txt">{{ticket.sender}}</span>
          </div>
        </div>

        <div class="line"> 
          <div class="line-block">
            <span class="cell">收件人：</span> 
            <span class="txt">{{ticket.receiver}}</span>
          </div>
          <div class="line-block">
            <span class="cell">電&nbsp;&nbsp;話：</span>
            <span class="txt">{{ticket.receiverTel}}</span>
          </div>
        </div>

        <div class="line-row">
          <span class="cell">地&nbsp;&nbsp;址：</span>
          <span class="txt">{{ticket.receiverAddr}}</span>
        </div>

        <div class="xt-wrap invoices-wrap">
          <table class="xtable invoice-table">
            <thead>
              <tr>
                <th>品名</th>
                <th>件數</th>
                <th>單價（NTD）</th>
                <th>金額（NTD）</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><span>{{ticket.expName}}</span></td>
                <td>{{ticket.qty}}</td>
                <td>{{ticket.price}}</td>
                <td>{{ticket.amount}}</td>
              </tr>
            </tbody>
          </table>
        </div><!--end invoices-->

        <div class="br" />

        <div class="xt-wrap orders-wrap">
          <table class="xtable order-table">
            <thead>
              <tr>
                <th v-if="!ticket.storePkg">商品名</th>
                <th v-if="ticket.storePkg">快递单号</th>
                <th v-if="ticket.storePkg">货架号</th>
                <th>规格</th>
                <th>件數</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(order, index) in ticket.orders" :key="index" >
                <td v-if="!ticket.storePkg"><span>{{order.name}}</span></td>
                <td v-if="ticket.storePkg"><span>{{order.code}}</span></td>
                <td v-if="ticket.storePkg"><span>{{order.storeShelfCode}}</span></td>
                <td><span>{{order.spec}}</span></td>
                <td>{{order.qty}}</td>
              </tr>
            </tbody>
          </table>
        </div><!--end invoices-->
        <div style="margin-top:5px; margin-bottom:5px; font-size:13px;">{{ticket.pkgCode}} / {{ticket.ticketTotal}}</div>
        <div v-if="ticket.remark" style="text-align:left;font-size:13px; margin: 0px 10px 10px 5px;">{{ticket.remark}}</div>
        
        <div class="date">{{ticket.created}}</div>
        <div v-if="idx < tickets.length - 1" style="page-break-after: always; margin-top:10px; margin-bottom:10px;"></div>
      </div>
     
    </div><!--end print-->
  </div>
</div>
</template> 

<script>
// @ is an alias to /src

export default {
  name: 'ExpInnerPrintTicketTpl',
  components: {

  },
  mounted:function(){
  },
  data(){
    return{
      code: null,
    } 
  }, 
  props:{
      tickets:Array,
  },
  methods: {
     getStyle(dto){
      if(dto.newPage){
        let style = {width: "400px"}
        if(dto.expCompany==='7-11'){
          style = {width: "360px"};
        }else if(dto.expCompany === '全家'){
          if(dto.platform == 1){
            style={width: '390px'}
          }else{
            style={width: '380px'}
          }
        }else if(dto.expCompany=='莱尔富'){
          if(dto.platform == 2){
            style={width: '380px'}
          }
        }
        return style;
      }else{
        let style = {};
        if(dto.expCompany == '7-11'){
            style= {
              width: "340px",
              maxHeight: "600px"
            }
        }else if(dto.expCompany == 'OK'){
            style={
              height:"560px"
            }
        }else if(dto.expCompany == '全家'){
            style={
              height: "600px"
            }
        }else if(dto.expCompany != "7-11"){
          style={
            width: "400px",
            maxHeight: "600px"
          }
        }
        return style;
      }
    },
  }
}
</script>

<style scoped>

.panel-wrap{text-align:center; padding:15px; text-align:center; background: white;}
.print{margin: 0px auto;}
.print-body{border: 1px solid #999; width:360px; margin: 0px auto;}

.print-body h1{font-size:20px; font-weight: bold; margin: 0px 0px 10px 0px;}
.print-body .line{display:flex; justify-content: space-between; padding: 0 10px; font-size:14px;}
.print-body .line-block{display:flex;  align-items: flex-end; }
.print-body .cell{width:60px; text-align:right;}
.print-body .txt{font-size:12px; width:110px; text-align:left; overflow:hidden; white-space:nowrap; margin-top:3px;}

.print-body .line-row{text-align:left; display: flex;}
.print-body .line-row .cell{display:inline-block; width:70px;}
.print-body .line-row .txt{overflow:hidden; word-wrap: break-word; width: calc(100% - 70px);}

.xtable{width:100%;}
.xtable th{font-size:12px; text-align:center;   
}
.xtable thead th{border-right: 1px solid #8a8a8a; text-align:center;}
.xt-wrap{padding:5px;}
.xtable{border-top: 1px solid #8a8a8a; border-left: 1px solid #8a8a8a; 
  border-collapse:collapse; border-spacing: 0px; 
}
.xtable tbody td{
  border-top: 1px solid #8a8a8a; 
  border-bottom: 1px solid #8a8a8a;
  border-right: 1px solid #8a8a8a;
  font-size:12px;
}

.print-body .date{text-align:right; margin-right:5px; margin-bottom:5px; font-size:12px;}

.invoice-table tbody td:nth-child(1){width:100px; white-space: nowrap; overflow:hidden;}
.invoice-table tbody td:nth-child(2){width:35px;}
.invoice-table tbody td:nth-child(3){width:80px; }


.order-table tbody td:nth-child(2){width:45px; white-space: nowrap;}

.invoice-table tbody td:nth-child(1) span{display:inline-block;width:100px; white-space: nowrap; overflow:hidden;}
 
.order-table tbody td:nth-child(1) span{display:inline-block; white-space: nowrap; overflow:hidden; width: 160px;} 
.order-table tbody td:nth-child(2) span{display:inline-block;width:100px; white-space: nowrap; overflow:hidden;}
.order-table tbody td:nth-child(3){width:30px; }

.print-body .br{border-bottom: 2px black dashed; margin-bottom: 10px; margin-top:10px;}

</style>
