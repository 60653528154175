<!--国内面单打印-->
<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('国内面单打印')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
  </el-row>

  <el-row :gutter="10"  class="query-wrap" >
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input v-if="!autoPrint"  :placeholder="msg('国内快递单号')" v-model="code" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
        <el-input v-else :placeholder="msg('国内快递单号')" ref="inputer1" @keyup.native="autoSearch" v-model="inputCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
       <el-input v-if="!autoPrint"  :placeholder="msg('物流袋号')" v-model="pkgCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-suitcase"></i>
        </el-input>
        <el-input v-else :placeholder="msg('物流袋号')" ref="inputer2" @keyup.native="autoSearchPkgCode" v-model="inputPkgCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-suitcase"></i>
        </el-input>
    </el-col>
  </el-row>

  <el-row :gutter="10" type="flex" justify="space-between" align="center" class="ctrl-wrap">
    <el-col :span="24">
      <span style="font-size:13px; margin-top:5px; margin-right:4px;display:inline-block;">{{msg('开启自动打印')}}</span>
      <el-switch v-model="autoPrint" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
      <el-button type="success" @click="prepareSearch()" size="mini" style="margin-left: 10px; ">
        <i class="el-icon-printer" style="display:inline-block;margin-right:4px; "></i>
        <transition name="el-fade-in-linear">
          <span v-show="!isMinScreen">{{msg('打印')}}</span>
        </transition>
      </el-button>
      <!--
      <el-button type="success" @click="doPrint()" size="mini" style="margin-left: 10px; ">
        <i class="el-icon-printer" style="display:inline-block;margin-right:4px; "></i>
        <transition name="el-fade-in-linear">
          <span v-show="!isMinScreen">{{msg('手动打印')}}</span>
        </transition>
      </el-button>
      -->
    </el-col>
  </el-row>
  
  <div :style="{height: docHeight+'px'}">
    <vue-easy-print tableShow ref="easyPrint">
      <template>
        <ticket-tpl :tickets="tickets"></ticket-tpl>
      </template>
    </vue-easy-print>
  </div>
</div>
</template> 

<script>
// @ is an alias to /src
import vueEasyPrint from "vue-easy-print";
import TicketTpl from "./ExpInnerPrintTicketTpl";

export default {
  name: 'ExpPrintPage',
  components: {
    vueEasyPrint:vueEasyPrint,
    TicketTpl:TicketTpl 
  },
  mounted:function(){
  },
  data(){
    return{
      code: null,
      prevCode: null,

      pkgCode: null,
      prevPkgCode: null,

      tickets: [],
      
      autoPrint: false,
      inputing: false,
      timer: null,
      timer1: null,
      actived: null,
      totalImages: 0,
    } 
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    inputCode: {
      get(){
        return this.code;
      },
      set(newValue){
        this.preCode = this.code;
        this.code = newValue;
      }
    },
    inputPkgCode: {
      get(){
        return this.pkgCode;
      },
      set(newValue){
        this.prevPkgCode = this.pkgCode;
        this.pkgCode = newValue;
      }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 238;
        }
    }
  },
  methods: {
    prepareSearch(){
      if(this.code){
        this.search(this.code, 2);
      }else if(this.pkgCode){
        this.search(this.pkgCode, 3);
      }

    },
    search(code, type){
      
      if(!(this.code || this.pkgCode)){
        this.$message("订单编号或者袋号必须有一个不能为空");
        return;
      }

      this.totalImages = 0;

      this.axios.get(this.$kit.api.expPrintGet+"?ps=2,3&code="+code+"&type="+type).then((result) => {
        if(result.status){
          var datas = result.data;
          this.tickets.splice(0);
          // for(var i=0; i<datas.length; i++){
          //   this.pages.push({
          //     id: datas[i],
          //     url: this.$kit.api.pdf+"?id="+datas[i]
          //   });
          // }
          this.tickets = datas;

          setTimeout(()=>{
            this.doPrint();
          }, 1000);

        }else{
          this.$message(result.msg);
          this.clearInputer();
        }
        
      }).catch(function (error) {
        console.error(error);
        this.clearInputer();
      });

    },
    clearInputer(){
      this.code = null;
      this.prevCode = null;
    
      this.pkgCode = null;
      this.prevPkgCode = null; 
    },
    doPrint(){
      
      this.$refs.easyPrint.print();

      if(this.autoPrint){
        this.clearInputer();

        // 正确代码
        this.$nextTick(() => {

          if(this.actived){
            if(this.actived=='code'){
              this.$refs.inputer1.focus();
            }else{
              this.$refs.inputer2.focus();
            }
          }

        });

      }
    },
    autoSearch(){
      var _this = this;
      //debugger;
      if(this.inputing == false){
        this.inputing = true;
        this.actived = 'code';
        this.timer = setInterval(()=>{
          if(_this.inputing && _this.code){
            if(_this.prevCode == _this.code){
              clearInterval(_this.timer);
              _this.timer = null;
              _this.inputing = false;
              _this.search(this.code, 2);
            }else{
              _this.prevCode = _this.code;
            }
          }
          
        }, 1000);
      }
    },
    autoSearchPkgCode(){
      var _this = this;
      //debugger;
      if(this.inputing == false){
        this.inputing = true;
        this.actived = 'pkgCode';
        this.timer = setInterval(()=>{
          if(_this.inputing && _this.pkgCode){
            if(_this.prevPkgCode == _this.pkgCode){
              clearInterval(_this.timer);
              _this.timer = null;
              _this.inputing = false;
              _this.search(this.pkgCode, 3);
            }else{
              _this.prevPkgCode = _this.pkgCode;
            }
          }
          
        }, 1000);
      }
    },
  }
   
  
}
</script>

<style scoped>

</style>
